<template>
  <div style="overflow-y: auto;
    overflow-x: hidden;">
    <a-row :gutter="[12, 18]" align="top">
      <a-col span="23" offset="1" class="row-box">
        <a-row>
          <div style="color: #f3d585; font-size: 22px; margin: 20px">
            <router-link :to="{ name: '首页' }" style="color: #f3d585">
              首页</router-link
            >>机关党建工作>{{ toproad }}
          </div>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col span="23">
            <a-row style="float: left; display: inline">
              <div
                class="menuLevelThree"
                @mouseover="MouseOver1"
                @mouseleave="MouseLeave1"
                @click="showNextModule"
                :data-value="item.value"
                :data-label="item.label"
                v-for="(item, index) in organList"
                :key="index"
              >
                {{ item.label }}
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row style="height: 50px"></a-row>
    <a-row>
      <a-col :span="23" offset="1">
        <!-- 子菜单选项 -->
        <!-- 行1 -->

        <bureau
          :SubMenu="NextModule.children"
          v-if="showbureau"
          :key="refresh"
        ></bureau>

        <grassRoots
          :SubMenu="NextModule.children"
          v-if="showgrassRoots"
          :key="refresh"
        ></grassRoots>

        <!-- 行2 -->
        <!-- 子菜单选项 -->
      </a-col>
    </a-row>

    <div
      style="
        position: fixed;
        bottom: 2%;
        right: 2%;
        width: 150px;
        height: 70px;
        padding: 25px;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: center;
      "
      :style="{
        backgroundImage: 'url(' + goBack + ')',
      }"
    >
      <router-link
        :to="{ name: '首页' }"
        style="color: #f3d585; width: 100%; height: 100%"
      >
        <div style="width: 100%; height: 100%">
          <span>返回首页</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import grassRoots from "./子菜单/grassRoots.vue";
import bureau from "./子菜单/bureau.vue";
export default {
  components: { grassRoots, bureau },
  data() {
    return {
      organList: [],
      toproad: "基层党建工作",
      NextModuleLabel: "基层党建工作",
      NextModule: "", //子级以下菜单
      titlebackimg: require("../../../public/img/党建切图/机关党建工作-基层组/标题矩形-默认.png"),
      titlebackimgactive: require("../../../public/img/党建切图/机关党建工作-基层组/标题矩形-激活.png"),
      squarebackimg: require("../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png"),
      squarebackimgactive: require("../../../public/img/党建切图/机关党建工作-基层组/区域矩形-激活.png"),
      goBack: require("../../../public/img/党建切图/党建品牌建设/返回按钮.png"),
      showbureau: false,
      showgrassRoots: false,
      selectedKeys: "",
      refresh: 0,
    };
  },
  methods: {
    // 一级菜单列表获取
    getorganList() {
      var that = this;
      request({
        url: "/category/getCategoryName?cateName=机关党建工作",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.organList = res.data.data;
        this.firstShow();
      });
    },
    // 首次载入页面使用非局党组工作的第一个菜单
    firstShow() {
      this.showMenuDetile = false;
      this.showgrassRoots = true;
      let that = this;
      for (let i = 0; i < that.organList.length; i++) {
        console.log(that.organList[i]);
        if (that.organList[i].label != "局党组工作") {
          that.NextModule = that.organList[i];
          that.NextModuleLabel = that.organList[i].label;
          console.log(that.NextModule);
          return;
        }
      }
    },
    // 点击显示三级菜单
    showNextModule(e) {
      console.log(e.srcElement.dataset.value);
      console.log(e.srcElement.dataset.label);
      this.toproad = e.srcElement.dataset.label;
      let that = this;
      this.organList.forEach((element) => {
        if (element.value == e.srcElement.dataset.value) {
          that.NextModule = element;
          console.log(that.NextModule);
        }
      });
      if (e.srcElement.dataset.label != "局党组工作") {
        if (this.showgrassRoots == true) {
          this.refresh += 1;
        }
        this.showgrassRoots = true;
        this.showbureau = false;
      } else {
        this.showgrassRoots = false;
        this.showbureau = true;
      }
      this.NextModuleLabel = e.srcElement.dataset.label;
    },
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
    // 鼠标"悬停"页面二级菜单触发此方法
    MouseOver1(e) {
      // console.log(e);
      e.target.style.backgroundImage = "url(" + this.titlebackimgactive + ")";
    },

    MouseLeave1(e) {
      // console.log(e);
      e.target.style.backgroundImage = "url(" + this.titlebackimg + ")";
      // this.active = this.changebackgroundM1;
    },
  },
  created() {
    // 目录获取
    this.getorganList();
  },
  mounted() {
    this.selectedKeys = this.$route.name;
  },
  updated() {},
};
/*
import request from "@/utils/request";

export default {
  data() {
    return {
      listData: [],
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 10,
        // showQuickJumper: true,
      },
      fromPath: "",
    };
  },
  mounted() {
    this.fromPath = this.$route.name;
    var that = this;
    request({
      url: "/column/getCategoryList?cateName=军供站党支部",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res.data.data);
      res.data.data.forEach(function (value, index) {
          console.log(value,index);
          //"home_scroll_top"  图片，
            var item = new Object();
            item.label = value.label;
            item.cateSort = value.cateSort;
            item.cateRemark=value.cateRemark;
            that.listData.push(item);

      });
    });
  },
};
*/
</script>

<style scoped>
.row-box-height {
  margin-top: 0 px;
  margin-right: 0px;
  height: 220px;
  width: 600px;
}
/* .spanBlank {
  height: 220px;
}
.spanStyleheade {
  position: absolute;
  left: 180px;
  width: 350px;
  height: 40px;
  top: 40px;
  color: #f3d585;
  font-size: 25px;
}
.spanStyleSecheade {
  position: absolute;
  left: 180px;
  width: 220px;
  height: 40px;
  top: 90px;
  color: #f3d585;
  font-size: 20px;
}
.spanStyleSecheade2 {
  position: absolute;
  left: 180px;
  width: 220px;
  height: 40px;
  top: 120px;
  color: #f3d585;
  font-size: 20px;
}
.spanStyleSecheade3 {
  position: absolute;
  left: 180px;
  width: 220px;
  height: 40px;
  top: 150px;
  color: #f3d585;
  font-size: 20px;
}
.imgIcon {
  position: absolute;
  left: 60px;
  width: 80px;
  height: 80px;
  top: 30px;
  text-decoration: underline;
  color: #f3d585;
  font-size: 20px;
}
.returnBut {
  position: absolute;
  right: 85px;
  width: 100px;
  height: 100%;
  top: 710px;
  color: #f3d585;
  font-size: 25px;
}
.returnImgIcon {
  position: absolute;
  right: 45px;
  width: 180px;
  height: 60px;
  top: 700px;
  text-decoration: underline;
  color: #f3d585;
  font-size: 20px;
}
.sectitleSiz {
  position: absolute;
  left: 200px;
  top: 135px;
  font-weight: bold;
  font-size: 25px;
  color: #ffffaa;
}
.sectitleSiz2 {
  position: absolute;
  left: 200px;
  top: 170px;
  font-size: 20px;
  color: #ffffaa;
}
.iconPos {
  position: absolute;
  left: 80px;
  top: 120px;
} */
.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}

.menuLevelThree {
  color: #f3d585;
  font-size: 35px;
  text-align: center;
  background-size: 100% 100%;
  margin: 20px;
  padding: 20px;
  margin-left: 0;
  display: inline;
  cursor: pointer;
  background-image: url("../../../public/img/党建切图/机关党建工作-基层组/标题矩形-默认.png");
}

.menuLevelThree >>> :hover,
.menuLevelThree >>> :active {
  color: red;
  font-size: 35px;
  text-align: center;
  background-size: 100% 100%;
  margin: 20px;
  padding: 20px;
  margin-left: 0;
  display: inline;
  background-image: url("../../../public/img/党建切图/机关党建工作-基层组/标题矩形-激活.png");
}
</style>
