<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="[12, 18]" align="top">
      <a-col span="23" offset="1" class="row-box">
        <a-row>
          <div style="color: #f3d585; font-size: 22px; margin: 20px">
            <router-link :to="{ name: '首页' }" style="color: #f3d585">
              首页</router-link
            >>机关党建工作>基层党建工作>{{
              this.$route.params.toplabel
            }}>“三会一课”
          </div>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col
        :span="22"
        offset="1"
        style="
          width: 90%;
          height: 650px;
          background-size: 100% 100%;
          padding: 30px;
        "
        :style="{ backgroundImage: 'url(' + squarebackimg + ')' }"
      >
        <a-row>
          <a-col :span="18" class="title">"三会一课"</a-col>
        </a-row>
        <a-tabs :default-active-key="menuList[0].value" @change="callback">
          <a-tab-pane
            :key="item.value"
            :tab="item.label"
            v-for="item in menuList"
          >
            <a-row>
              <a-col :span="10"
                ><div
                  v-if="items != []"
                  style="width: 100%; height: 100%; position: relative"
                >
                  <a-carousel
                    arrows
                    dotsClass="slick-dots"
                    :afterChange="afterChangeC"
                  >
                    <div
                      slot="prevArrow"
                      class="custom-slick-arrow"
                      style="left: 10px; z-index: 9"
                    >
                      <a-icon type="arrow-left" />
                    </div>
                    <div
                      slot="nextArrow"
                      class="custom-slick-arrow"
                      style="right: 10px; z-index: 9"
                    >
                      <a-icon type="arrow-right" />
                    </div>
                    <div v-for="(v, i) in items" :key="i">
                      <img
                        style="width: 100%"
                        :src="v.scenImageAbsolutePath"
                        v-if="item.video == null"
                      />
                      <video
                        v-if="item.video != null"
                        width="1920"
                        height="978"
                        autoplay
                        loop
                        :poster="item.pic"
                        style="width: 1920px; top: 0; left: 0; object-fit: fill"
                      >
                        <source :src="item.video" type="video/mp4" />
                        您的浏览器不支持Video标签。{{ index }}
                      </video>
                    </div>
                  </a-carousel>
                  <div
                    style="
                      width: 100%;
                      position: absolute;
                      bottom: 0;
                      line-height: 100px;
                      height: 60px;
                      background-color: rgba(10, 10, 10, 0.8);
                      z-index: 99;
                    "
                  >
                    <div
                      style="
                        width: 74%;
                        margin: 0 auto;
                        position: relative;
                        font-size: 20px;
                        color: white;
                      "
                    >
                      <div style="float: left; display: inline">
                        <span
                          style="
                            float: left;
                            width: 30rem;
                            overflow: hidden;
                            display: -webkit-box;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-align: left;
                            height: 60px;
                            line-height: 60px;
                          "
                        >
                          <span style="color: red">{{ CIndex }}</span
                          >/{{ itemsLength }}
                          {{ activeScenTitle }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div></a-col
              >
              <a-col :span="14">
                <div style="padding: 0 20px">
                  <news
                    interfaceUrl="/category/getCategoryeNewsinfo?categoryId="
                    :categoryId="item.value"
                    pageSize="5"
                    :key="refresh"
                  ></news>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
  </div>
</template>

<script>
/* 轮播图使用数据 */
const carouselpic = [
  {
    id: 0,
    pic: require("../../../../public/img/党建切图/首页/轮播图/1.jpeg"),
  },
  {
    id: 1,
    pic: require("../../../../public/img/党建切图/首页/轮播图/2.jpeg"),
    // video: require("../../../../public/img/党建切图/首页/轮播图/1.mp4"),
  },
  {
    id: 2,
    pic: require("../../../../public/img/党建切图/首页/轮播图/3.jpeg"),
  },
  {
    id: 2,
    pic: require("../../../../public/img/党建切图/首页/轮播图/4.jpg"),
  },
];
import news from "./newsShowModule.vue";
import request from "@/utils/request";
export default {
  components: { news },
  data() {
    return {
      tabsList: "",
      CIndex: "1",
      carouselpic,
      squarebackimg: require("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png"),
      menuList: [],
      baseURL: "https://wzcpc.gxxqy.cn/admin/",
      activeScenTitle: "",
      itemsLength: "",
    };
  },
  methods: {
    afterChangeC(e) {
      this.CIndex = e + 1;
      this.activeScenTitle = this.items[e].scenTitle;
    },
    callback(e) {
      this.selectedKeys = e;
      this.refresh += 1;
    },
    // 菜单列表获取
    getmenuList(cateId) {
      var that = this;
      request({
        url: "/category/getCategoryId?cateId=" + cateId,
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.menuList = res.data.data;
        that.selectedKeys = that.menuList[0].value;
        that.refresh += 1;
      });
    },
    //获取首页轮播图
    getSlider() {
      var that = this;
      request({
        url: "/scrollable/getScrollableImage?scroType=image",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res.data.data);
        that.items = res.data.data;
        that.items = res.data.data[0].scrollableEntity;
        console.log(that.items);
        if (that.items.length) {
          that.itemsLength = that.items.length;
        }
        that.activeScenTitle = that.items[0].scenTitle;
      });
    },
  },
  mounted() {
    console.log(this.$route.params.value);
    if (this.$route.params.value == null) {
      this.$router.replace({ name: "机关党建工作" });
    }
    this.getmenuList(this.$route.params.value);
    this.getSlider();
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
  color: #f3d585;
  width: 100%;
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  color: #f3d585 !important;
  font-size: 16px;
  font-weight: 900;
}

/deep/ .ant-tabs-nav .ant-tabs-tab:hover {
  color: #f3d585;
  font-size: 16px;
}

/deep/ .ant-tabs-nav .ant-tabs-tab-active {
  color: #f3d585;
  font-size: 16px;
}

/deep/ .ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 2px solid #f3d6858c;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/deep/ .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 4px;
  background-color: #f3d585;
  transform-origin: 0 0;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 470px;
  width: 100%;
  line-height: 0;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel >>> .slick-dots {
  display: none !important;
  height: auto;
  margin: 0px auto;
  text-align: center;
  bottom: 110px;
}

.ant-carousel >>> .custom-slick-arrow {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>