<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="16" align="top">
      <a-col :span="2"></a-col>
      <a-col :span="4" class="row-box row-box-height">
        <a-row :gutter="[12, 18]">
          <a-col>
            <a-menu
              mode="inline"
              :selectedKeys="[selectedKeys]"
              @click="handleClick"
            >
              <a-menu-item
                v-for="item in organList"
                :key="item.value"
                style="
                  height: 90px;
                  width: calc(100%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  white-space: normal;
                  overflow-wrap: break-word;
                  word-break: break-all;
                  overflow: hidden;
                  border-radius: 10px;
                  background-color: transparent;
                  background: rgba(255, 255, 255, 0.3);
                  border-color: #f3d585;
                "
              >
                {{ item.label }}
              </a-menu-item>
            </a-menu>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="15" class="row-box row-box-height">
        <a-row>
          <a-col>
            <news
              interfaceUrl="/category/getCategoryeNewsinfo?categoryId="
              :categoryId="keyValue"
              pageSize="10"
              :key="refresh"
            ></news>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="3"></a-col>
    </a-row>
  </div>
</template>

<script>
const menuBag = [
  {
    id: 0,
    name: "党史学习教育动员大会",
    pic: require("../../../public/img/党建切图/首页/轮播图/1.jpeg"),
  },
  {
    id: 1,
    name: "开展专题学习",
    pic: require("../../../public/img/党建切图/首页/轮播图/2.jpeg"),
  },
  {
    id: 2,
    name: "加强政治引领",
    pic: require("../../../public/img/党建切图/首页/轮播图/3.jpeg"),
  },
  {
    id: 3,
    name: "组织专题培训",
    pic: require("../../../public/img/党建切图/首页/轮播图/1.jpeg"),
  },
  {
    id: 4,
    name: "开展“我为群众办实事”实践活动",
    pic: require("../../../public/img/党建切图/首页/轮播图/2.jpeg"),
  },
  {
    id: 5,
    name: "召开专题组织生活会",
    pic: require("../../../public/img/党建切图/首页/轮播图/3.jpeg"),
  },
];
import news from "./子菜单/newsShowModule.vue";
import request from "@/utils/request";
export default {
  components: { news },
  data() {
    return {
      organList: [],
      keyValue: "",
      menuBag,
      picbackground: require("../../../public/img/党建切图/党史学习教育/标题矩形-默认.png"),
      selectedKeys: "",
      refresh: 0,
    };
  },
  methods: {
    // 菜单点击选中、传递key给组件、强制刷新列表
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
      this.keyValue = e.key;
      this.refresh += 1;
    },
    // 一级菜单列表获取
    getorganList() {
      var that = this;
      request({
        url: "/category/getCategoryName?cateName=党史学习教育",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.organList = res.data.data;
        that.keyValue = that.organList[0].value;
        this.refresh += 1;

        // that.firstShow();
      });
    },
    // // 首次载入页面使用非局党组工作的第一个菜单
    // firstShow() {
    //   this.showMenuDetile = false;
    //   this.showgrassRoots = true;
    //   let that = this;
    //   for (let i = 0; i < that.organList.length; i++) {
    //     console.log(that.organList[i]);
    //     if (that.organList[i].label != "局党组工作") {
    //       that.NextModule = that.organList[i];
    //       that.NextModuleLabel = that.organList[i].label;
    //       console.log(that.NextModule);
    //       return;
    //     }
    //   }
    // },
  },
  mounted() {
    this.selectedKeys = this.$route.name;
  },
  created() {
    // 目录获取
    this.getorganList();
  },
};
</script>

<style scoped>
.row-box {
}
.row-box-height {
  margin-top: 24px;
  height: 691px;
}

.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}
</style>