<template>
  <div style="overflow: hidden">
    <a-row>
      <a-col :span="23">
        <!-- 子菜单选项 -->
        <!-- 行1 -->
        <a-row>
          <a-col
            :span="8"
            class="square"
            @mouseover.stop="MouseOver2"
            @mouseleave.stop="MouseLeave2"
            @click="jumpnews(item)"
            v-for="(item, index) in level3Menu.children"
            :key="index"
          >
            <a-row style="">
              <a-col :span="18">
                <span class="title">{{ item.label }}</span></a-col
              >
            </a-row>
          </a-col>

          <!-- 行1 -->
        </a-row>

        <!-- 子菜单选项 -->
      </a-col>
    </a-row>

    <div
      style="
        position: fixed;
        bottom: 2%;
        right: 2%;
        width: 150px;
        height: 70px;
        padding: 25px;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: center;
      "
      :style="{
        backgroundImage: 'url(' + goBack + ')',
      }"
    >
      <router-link
        :to="{ name: '首页' }"
        style="color: #f3d585; width: 100%; height: 100%"
      >
        <div style="width: 100%; height: 100%">
          <span>返回首页</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  props: {
    level3Menu: Object,
  },
  data() {
    return {
      organList: [],
      moduleList: [],
      titlebackimg: require("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-默认.png"),
      titlebackimgactive: require("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-激活.png"),
      squarebackimg: require("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png"),
      squarebackimgactive: require("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-激活.png"),
      goBack: require("../../../../public/img/党建切图/党建品牌建设/返回按钮.png"),
      selectedKeys: "",
    };
  },
  methods: {
    getorganList() {
      var that = this;
      request({
        url: "/category/getCategoryName?cateName=机关党建工作",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res.data.data);
        that.organList = res.data.data;
        // that.getModuleList(res.data.data[1].cateParentName);
      });
    },
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
    // 鼠标"悬停"页面二级菜单触发此方法
    MouseOver1(e) {
      // console.log(e);
      e.target.style.backgroundImage = "url(" + this.titlebackimgactive + ")";
    },

    MouseLeave1(e) {
      // console.log(e);
      e.target.style.backgroundImage = "url(" + this.titlebackimg + ")";
      // this.active = this.changebackgroundM1;
    },
    // 鼠标"悬停"页面三级菜单触发此方法
    MouseOver2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimgactive + ")";
    },
    MouseLeave2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimg + ")";
    },
    jumpnews(item) {
      if (item.label.indexOf("三会一课") != -1) {
        this.$router.push({
          name: '"三会一课"制度落实情况',
          params: { value: item.value, toplabel: this.level3Menu.label },
        });
      } else {
        this.$router.push({
          name: "基层党建工作分类新闻",
          params: {
            value: item.value,
            label: item.label,
            toplabel: this.level3Menu.label,
          },
        });
      }
    },
  },
  mounted() {
    this.selectedKeys = this.$route.name;
    console.log(this.level3Menu);
    this.getorganList();
  },
};
/*
import request from "@/utils/request";

export default {
  data() {
    return {
      listData: [],
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 10,
        // showQuickJumper: true,
      },
      fromPath: "",
    };
  },
  mounted() {
    this.fromPath = this.$route.name;
    var that = this;
    request({
      url: "/column/getCategoryList?cateName=军供站党支部",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res.data.data);
      res.data.data.forEach(function (value, index) {
          console.log(value,index);
          //"home_scroll_top"  图片，
            var item = new Object();
            item.label = value.label;
            item.cateSort = value.cateSort;
            item.cateRemark=value.cateRemark;
            that.listData.push(item);

      });
    });
  },
};
*/
</script>

<style scoped>
.row-box-height {
  margin-top: 0 px;
  margin-right: 0px;
  height: 220px;
  width: 600px;
}

.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}

.menuLevelThree {
  color: #f3d585;
  font-size: 35px;
  text-align: center;
  background-size: 100% 100%;
  margin: 20px;
  padding: 20px;
  margin-left: 0;
  display: inline;
  background-image: url("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-默认.png");
}

.menuLevelThree >>> :hover,
.menuLevelThree >>> :active {
  color: red;
  font-size: 35px;
  text-align: center;
  background-size: 100% 100%;
  margin: 20px;
  padding: 20px;
  margin-left: 0;
  display: inline;
  background-image: url("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-激活.png");
}

.square {
  width: 500px;
  height: 80px;
  margin: 10px 30px 30px 0;
  padding: 15px;
  display: inline;
  background-size: 100% 100%;
  color: #f3d585;
  position: relative;
  background-image: url("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png");
  cursor: pointer;
}

.title {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
}
.sub {
  font-size: 16px;
  display: block;
}
</style>
