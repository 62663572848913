<template>
  <div style="overflow: hidden">
    <a-row :gutter="16" align="top">
      <a-col :span="23" class="row-box row-box-height">
        <a-row :gutter="[12, 18]">
          <a-col :span="6">
            <a-menu
              mode="inline"
              :selectedKeys="[selectedKeys]"
              @click="handleClick"
            >
              <a-menu-item
                v-for="item in SubMenu1"
                :key="item.value"
                style="
                  height: 70px;
                  width: calc(100%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  white-space: normal;
                  overflow-wrap: break-word;
                  word-break: break-all;
                  overflow: hidden;
                  border-radius: 10px;
                  background-color: transparent;
                  background: rgba(255, 255, 255, 0.3);
                  border-color: #f3d585;
                "
              >
                {{ item.label }}
              </a-menu-item>
            </a-menu>
          </a-col>
          <a-col :span="18" class="row-box row-box-height">
            <news
              :valueid="value"
              interfaceUrl="/category/getCategoryeNewsinfo?categoryId="
              :categoryId="selectedKeys"
              pageSize="6"
              :key="refresh"
            ></news>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const picturebag = [
  {
    id: 0,
    name: "党史学习教育动员大会",
    pic: require("../../../../public/img/党建切图/首页/轮播图/1.jpeg"),
  },
  {
    id: 1,
    name: "开展专题学习",
    pic: require("../../../../public/img/党建切图/首页/轮播图/2.jpeg"),
  },
  {
    id: 2,
    name: "加强政治引领",
    pic: require("../../../../public/img/党建切图/首页/轮播图/3.jpeg"),
  },
  {
    id: 3,
    name: "组织专题培训",
    pic: require("../../../../public/img/党建切图/首页/轮播图/1.jpeg"),
  },
  {
    id: 4,
    name: "开展“我为群众办实事”实践活动",
    pic: require("../../../../public/img/党建切图/首页/轮播图/2.jpeg"),
  },
  {
    id: 5,
    name: "召开专题组织生活会",
    pic: require("../../../../public/img/党建切图/首页/轮播图/3.jpeg"),
  },
];
import news from "./newsShowModule.vue";
export default {
  name: "bureau",
  props: { SubMenu: Array },
  components: { news },
  data() {
    return {
      carousel: 1,
      picturebag,
      picbackground: require("../../../../public/img/党建切图/党史学习教育/标题矩形-默认.png"),
      selectedKeys: "",
      value: "",
      SubMenu1: this.SubMenu,
      refresh: 0,
    };
  },
  methods: {
    handleClick(e) {
      console.log("click ", e);
      console.log(this.SubMenu);
      this.selectedKeys = e.key;
      this.refresh += 1;
    },
  },
  mounted() {
    this.selectedKeys = this.$route.name;
    this.selectedKeys = this.SubMenu[0].value;
    this.refresh += 1;
  },
};
</script>

<style scoped>
.row-box-height {
  height: 100%;
}

.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}
</style>