<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="[12, 18]" align="top">
      <a-col span="23" offset="1" class="row-box">
        <a-row>
          <div style="color: #f3d585; font-size: 22px; margin: 20px">
            <router-link :to="{ name: '首页' }" style="color: #f3d585">
              首页</router-link
            >>机关党建工作>基层党建工作>{{ this.$route.params.toplabel }}
          </div>
        </a-row>
        <a-row>
          <a-col span="23">
            <a-row :gutter="16" align="top" style="top: 25px; height: 700px">
              <a-col :span="6" class="row-box row-box-height">
                <a-row :gutter="[12, 18]">
                  <a-col
                    style="
                      border-radius: 10px;
                      background-color: transparent;
                      background: rgba(255, 255, 255, 0.3);
                      border-color: #f3d585;
                    "
                  >
                    <a-menu
                      mode="inline"
                      :selectedKeys="[selectedKeys]"
                      @click="handleClick"
                    >
                      <a-menu-item
                        style="
                          height: 90px;
                          width: calc(100%);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          text-align: center;
                          white-space: normal;
                          overflow-wrap: break-word;
                          word-break: break-all;
                          overflow: hidden;
                          border-radius: 10px;
                          background-color: transparent;
                          background: rgba(255, 255, 255, 0.3);
                          border-color: #f3d585;
                        "
                      >
                        {{ this.$route.params.label }}
                      </a-menu-item>
                    </a-menu>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="18" class="row-box row-box-height">
                <news
                  :valueid="value"
                  interfaceUrl="/category/getCategoryeNewsinfo?categoryId="
                  :categoryId="this.$route.params.value"
                  pageSize="8"
                  :key="refresh"
                ></news>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const menuBag = [
  {
    id: 0,
    name: "党史学习教育动员大会",
  },
  {
    id: 1,
    name: "开展专题学习",
  },
  {
    id: 2,
    name: "加强政治引领",
  },
  {
    id: 3,
    name: "组织专题培训",
  },
  {
    id: 4,
    name: "开展“我为群众办实事”实践活动",
  },
  {
    id: 5,
    name: "召开专题组织生活会",
  },
];
import news from "./newsShowModule.vue";
import request from "@/utils/request";
export default {
  components: { news },
  data() {
    return {
      menuBag,
      picbackground: require("../../../../public/img/党建切图/党史学习教育/标题矩形-默认.png"),
      selectedKeys: "",
      menuList: [],
      refresh: 0,
    };
  },
  methods: {
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
    // 菜单列表获取
    getmenuList(cateId) {
      var that = this;
      request({
        url: "/category/getCategoryId?cateId=" + cateId,
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.menuList = res.data.data;
        that.selectedKeys = that.menuList[0].value;
        that.refresh += 1;
      });
    },
  },
  mounted() {
    this.selectedKeys = this.$route.name;
    if (this.$route.params.label == null) {
      this.$router.replace({ name: "机关党建工作" });
    }
    this.refresh += 1;
  },
};
</script>

<style scoped>
.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}

.row-box-height {
  height: 100%;
}

.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}
</style>
