<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="[12, 18]" align="top">
      <a-col span="23" offset="1" class="row-box">
        <a-row>
          <div style="color: #f3d585; font-size: 22px; margin: 20px">
            <router-link :to="{ name: '首页' }" style="color: #f3d585">
              首页</router-link
            >>“双拥先锋”党建品牌建设
          </div>
        </a-row>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="23" offset="1">
        <a-row :gutter="[12, 18]" style="margin-top: 20px">
          <a-col
            :span="8"
            class="square"
            v-for="(item, index) in menuList"
            :key="item.value"
            @mouseover.stop="MouseOver2"
            @mouseleave.stop="MouseLeave2"
            @click="jumpnews(item)"
          >
            <a-row>
              <a-col :span="6" style="text-align: center"
                ><img
                  style="width: 60%; height: 60%; margin-top: 10%"
                  :src="squareIcon[index % 5].pic"
              /></a-col>
              <a-col :span="18"
                ><span class="title">{{ item.label }}</span>
                <span
                  class="sub"
                  v-for="(item2, index2) in item.children"
                  :key="index2"
                  >{{ item2.label }}</span
                >
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="3"></a-col>
    </a-row>

    <div
      style="
        position: fixed;
        bottom: 2%;
        right: 2%;
        width: 150px;
        height: 70px;
        padding: 25px;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        text-align: center;
      "
      :style="{
        backgroundImage: 'url(' + goBack + ')',
      }"
    >
      <router-link
        :to="{ name: '首页' }"
        style="color: #f3d585; width: 100%; height: 100%"
      >
        <div style="width: 100%; height: 100%">
          <span>返回首页</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
const squareIcon = [
  {
    pic: require("../../../public/img/党建切图/党建品牌建设/局机关党支部-图标.png"),
  },
  {
    pic: require("../../../public/img/党建切图/党建品牌建设/军供站党支部-图标.png"),
  },
  {
    pic: require("../../../public/img/党建切图/党建品牌建设/退役军人服务中心党支部-图标.png"),
  },
  {
    pic: require("../../../public/img/党建切图/党建品牌建设/军休所党支部-图标.png"),
  },
  {
    pic: require("../../../public/img/党建切图/党建品牌建设/梧州市爱国拥军促进会党支部-图标.png"),
  },
];

import request from "@/utils/request";
export default {
  data() {
    return {
      selectedKeys: "",
      squareIcon,
      squarebackimg: require("../../../public/img/党建切图/党建品牌建设/块面矩形-默认.png"),
      squarebackimgactive: require("../../../public/img/党建切图/党建品牌建设/块面矩形-激活.png"),
      goBack: require("../../../public/img/党建切图/党建品牌建设/返回按钮.png"),
      menuList: [],
    };
  },
  methods: {
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
    // 鼠标"悬停"页面三级菜单触发此方法
    MouseOver2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimgactive + ")";
    },
    MouseLeave2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimg + ")";
    },
    // 菜单列表获取
    getmenuList() {
      var that = this;
      request({
        url: "/category/getCategoryName?cateName=“双拥先锋”党建品牌建设",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.menuList = res.data.data;
      });
    },
    jumpnews(item) {
      this.$router.push({
        name: "党建品牌建设-局机关党支部",
        params: { value: item.value, label: item.label },
      });
    },
  },
  mounted() {
    this.selectedKeys = this.$route.name;
    this.getmenuList();
  },
};
/*
import request from "@/utils/request";

export default {
  data() {
    return {
      listData: [],
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 10,
        // showQuickJumper: true,
      },
      fromPath: "",
    };
  },
  mounted() {
    this.fromPath = this.$route.name;
    var that = this;
    request({
      url: "/column/getCategoryList?cateName=军供站党支部",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res.data.data);
      res.data.data.forEach(function (value, index) {
          console.log(value,index);
          //"home_scroll_top"  图片，
            var item = new Object();
            item.label = value.label;
            item.cateSort = value.cateSort;
            item.cateRemark=value.cateRemark;
            that.listData.push(item);
           
      });
    });
  },
};
*/
</script>

<style scoped>
.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}

.square {
  width: 500px;
  height: 250px;
  margin: 10px 30px 30px 0;
  padding: 15px;
  display: inline;
  background-size: 100% 100%;
  color: #f3d585;
  position: relative;
  background-image: url("../../../public/img/党建切图/党建品牌建设/块面矩形-默认.png");
  cursor: pointer;
}

.title {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
}
.sub {
  font-size: 25px;
  display: block;
  margin-top: 20px;
}
</style>