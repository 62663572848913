<template>
  <div style="overflow-y: auto; overflow-x: hidden">
    <a-row :gutter="[12, 18]">
      <a-col :span="1"></a-col>
      <a-col :span="20">
        <a-row>
          <div style="color: #f3d585; font-size: 22px; margin: 20px">
            <router-link :to="{ name: '首页' }" style="color: #f3d585">
              首页</router-link
            >>党建100周年庆
          </div>
        </a-row>
        <a-row class="custom-body1">
          <a-col span="12">
            <div style="padding: 30px">
              <div id="Carousel" style="height: 100%; width: 100%">
                <router-link :to="{ name: '党建100周年' }">
                  <img
                    v-for="(v, i) in items"
                    style="height: 100%; width: 100%"
                    :key="i"
                    :src="v.scenImageAbsolutePath"
                    v-show="n == i"
                  />
                  <!-- <div class="spanStyleheade">{{ v.scenTitle }}</div> -->
                </router-link>
              </div>
            </div>
          </a-col>
          <a-col span="12">
            <div style="width: 100%; height: 100%; padding: 30px">
              <p style="color: #f3d585; font-size: 35px">
                {{ menuList[0] != null ? menuList[0].label : "" }}
              </p>
              <p
                v-for="(item, index) in listData1"
                :key="index"
                style="color: #f3d585; font-size: 20px"
              >
                <router-link
                  :to="{
                    name: '文章',
                    params: { articleid: item.neinId, fromPath },
                  }"
                  style="color: #f3d585; font-size: 20px"
                  >·{{ item.title }}</router-link
                >
              </p>
              <div
                style="border-bottom: 1px solid white; padding-top: 20px"
              ></div>
            </div>
            <div
              style="width: 100%; height: 100%; padding: 30px; padding-top: 0"
            >
              <p style="color: #f3d585; font-size: 35px">
                {{ menuList[1] != null ? menuList[1].label : "" }}
              </p>
              <p
                v-for="(item, index) in listData2"
                :key="index"
                style="color: #f3d585; font-size: 20px"
              >
                <router-link
                  :to="{
                    name: '文章',
                    params: { articleid: item.neinId, fromPath },
                  }"
                  style="color: #f3d585; font-size: 20px"
                  >·{{ item.title }}</router-link
                >
              </p>
            </div>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="3"></a-col>
    </a-row>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      n: 0,
      items: [],
      baseURL: "https://wzcpc.gxxqy.cn/admin/",
      selectedKeys: "",
      videoList: [],
      videoItem: {
        title: "",
        url: "",
      },
      menuList: [],
      listData1: [],
      listData2: [],
    };
  },
  methods: {
    getSlider() {
      var that = this;
      request({
        url: "/scrollable/getScrollableImage?scroType=image",
        method: "GET",
        params: null,
      }).then((res) => {
        // console.log("res= ",res.data.data)
        that.items = res.data.data[0].scrollableEntity;

        that.videoList = res.data.data;
        that.videoItem.title = res.data.data[0].scroName;
        that.videoItem.url =
          res.data.data[0].scrollableEntity[0].scenImageAbsolutePath;
      });
    },
    handleClick(e) {
      // console.log("click ", e);
      // console.log(this.$route);
      this.selectedKeys = e.key;
    },
    play: function () {
      setInterval(this.autoPlay, 2000);
    },
    autoPlay: function () {
      this.n++;
      if (this.n == this.items.length) {
        this.n = 0;
      }
    },
    // 菜单列表获取
    getmenuList() {
      var that = this;
      request({
        url: "/category/getCategoryName?cateName=党建100周年庆",
        method: "GET",
        params: null,
      }).then((res) => {
        console.log("res= ", res);
        that.menuList = res.data.data;
        that.getNews(that.menuList);
      });
    },
    getNews(menuList) {
      var that = this;
      request({
        url: "/category/getCategoryeNewsinfo?categoryId=" + menuList[0].value,
        method: "GET",
        params: null,
      }).then((res) => {
        // console.log("res= ",res)
        console.log(res);
        if (res.data.data.records != null) {
          for (let i = 0; i < res.data.data.records.length && i < 3; i++) {
            var item = new Object();
            item.title = res.data.data.records[i].neinNewsname;
            item.time = res.data.data.records[i].neinStartdate;
            item.neinId = res.data.data.records[i].neinId;
            that.listData1.push(item);
          }

          // that.menuList[0].push(that.listData1);
        }
      });

      request({
        url: "/category/getCategoryeNewsinfo?categoryId=" + menuList[1].value,
        method: "GET",
        params: null,
      }).then((res) => {
        // console.log("res= ",res)
        console.log(res);
        if (res.data.data.records != null) {
          for (let i = 0; i < res.data.data.records.length && i < 3; i++) {
            var item = new Object();
            item.title = res.data.data.records[i].neinNewsname;
            item.time = res.data.data.records[i].neinStartdate;
            item.neinId = res.data.data.records[i].neinId;
            that.listData2.push(item);
          }
          // that.menuList[1].push(that.listData2);
        }
      });

      console.log(that.listData1);
      console.log(that.listData2);
      console.log(that.menuList);
    },
  },
  mounted() {
    // this.play();
    this.selectedKeys = this.$route.name;
    this.fromPath = this.$route.name;
    this.play();
    this.getSlider();
    this.getmenuList();
  },
};
</script>

<style scoped>
.custom-body1 {
  background-image: url("../../../public/img/党建切图/党建100周年/矩形大.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 650px !important;
  width: 1680px;
  left: 0px;
  padding: 50px;
}
.custom-body-rollpic {
  /*background-image: url("../../../public/img/党建切图/党建100周年/轮播配图样式.png");*/
  /*background-repeat: no-repeat;*/
  /*background-size: 100% 80%;*/
  height: 500px;
  width: 50%;
  margin: 50px 0 0 100px;
  position: relative;
}
.custom-body-rollpic video {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}
.custom-body-rollpic p {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  margin: 0;
  font-size: 16px;
  padding: 10px 20px;
}
.row-box-height {
  margin-top: 0 px;
  height: 750px;
  width: 600px;
}
.spanBlank {
  height: 220px;
}
.spanStyleheade {
  position: absolute;
  left: -25px;
  width: 700px;
  height: 100%;
  top: 70px;
  color: #f3d585;
  font-size: 35px;
}
.spanStyleSecheade0 {
  position: absolute;
  left: -25px;
  width: 700px;
  height: 100%;
  top: 150px;
  color: #f3d585;
  font-size: 20px;
}
.spanStyleSecheade1 {
  position: absolute;
  left: -25px;
  width: 700px;
  height: 100%;
  top: 200px;
  color: #f3d585;
  font-size: 20px;
}
.spanStyleSecheade2 {
  position: absolute;
  left: -25px;
  width: 700px;
  height: 100%;
  top: 250px;
  color: #f3d585;
  font-size: 20px;
}
.imgIcon {
  position: absolute;
  left: 60px;
  width: 80px;
  height: 80px;
  top: 30px;
  text-decoration: underline;
  color: #f3d585;
  font-size: 20px;
}
.returnBut {
  position: absolute;
  right: 100px;
  width: 100px;
  height: 100%;
  top: 670px;
  color: #f3d585;
  font-size: 25px;
}
.returnImgIcon {
  position: absolute;
  right: 45px;
  width: 80px;
  height: 80px;
  top: 650px;
  text-decoration: underline;
  color: #f3d585;
  font-size: 20px;
}
.sectitleSiz {
  position: absolute;
  left: 200px;
  top: 135px;
  font-weight: bold;
  font-size: 25px;
  color: #ffffaa;
}
.sectitleSiz2 {
  position: absolute;
  left: 200px;
  top: 170px;
  font-size: 20px;
  color: #ffffaa;
}
.iconPos {
  position: absolute;
  left: 80px;
  top: 120px;
}
.ant-menu-item {
  float: right;
  color: #f6de8d !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-active {
  float: right;
  color: #f6de8d !important;
  background-color: transparent !important;
  background: rgba(255, 255, 255, 0.5) !important;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 20px;
  font-weight: lighter;
}

.ant-menu-item-selected {
  float: right;
  color: #f6de8d !important;
  border-bottom: 0px !important;
  background-color: #990101;
  background-image: unset;
  font-size: 22px;
  font-weight: 900;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-item > a {
  color: #f6de8d !important;
}
</style>
