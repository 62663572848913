<template>
  <div>
    <a-row v-if="showDetile">
      <category :level3Menu="showItem"></category>
    </a-row>
    <a-row v-if="!showDetile">
      <a-col
        :span="8"
        class="square"
        @mouseover.stop="MouseOver2"
        @mouseleave.stop="MouseLeave2"
        @click="showMenuDetail(item)"
        v-for="(item, index) in SubMenu"
        :key="index"
      >
        <a-row style="">
          <a-col :span="6" style="text-align: center"
            ><img
              style="width: 60%; height: 60%; margin-top: 10%"
              :src="squareIcon[index % 5].pic"
          /></a-col>
          <a-col :span="18"
            ><span class="title">{{ item.label }}</span>
            <span
              class="sub"
              v-for="(item2, index2) in item.children"
              :key="index2"
              >{{ item2.label }}</span
            >
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const squareIcon = [
  {
    pic: require("../../../../public/img/党建切图/机关党建工作-基层组/局党支部-图标.png"),
  },
  {
    pic: require("../../../../public/img/党建切图/机关党建工作-基层组/局机关党支部-图标.png"),
  },
  {
    pic: require("../../../../public/img/党建切图/机关党建工作-基层组/军供站党支部-图标.png"),
  },
  {
    pic: require("../../../../public/img/党建切图/机关党建工作-基层组/军休所党支部-图标.png"),
  },
  {
    pic: require("../../../../public/img/党建切图/机关党建工作-基层组/梧州市爱国拥军促进会党支部-图标.png"),
  },
];

import category from "./category.vue";
export default {
  components: { category },
  props: { SubMenu: Array },
  watch: {
    SubMenu() {
      console.log(this.SubMenu);
    },
  },
  data() {
    return {
      titlebackimg: require("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-默认.png"),
      titlebackimgactive: require("../../../../public/img/党建切图/机关党建工作-基层组/标题矩形-激活.png"),
      squarebackimg: require("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png"),
      squarebackimgactive: require("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-激活.png"),
      goBack: require("../../../../public/img/党建切图/党建品牌建设/返回按钮.png"),
      selectedKeys: "",
      showDetile: false,
      squareIcon,
    };
  },
  methods: {
    // 鼠标"悬停"页面三级菜单触发此方法
    MouseOver2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimgactive + ")";
    },
    MouseLeave2(event) {
      // console.log(event);
      // console.log(event.currentTarget);
      event.currentTarget.style.backgroundImage =
        "url(" + this.squarebackimg + ")";
    },
    showMenuDetail(level3Menu) {
      console.log(level3Menu);
      this.showDetile = true;
      this.showItem = level3Menu;
    },
  },
  computed: {},
};
</script>

<style scoped>
.square {
  width: 500px;
  height: 250px;
  margin: 10px 30px 30px 0;
  padding: 15px;
  display: inline;
  background-size: 100% 100%;
  color: #f3d585;
  position: relative;
  background-image: url("../../../../public/img/党建切图/机关党建工作-基层组/区域矩形-默认.png");
  cursor: pointer;
}

.title {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
}
.sub {
  font-size: 16px;
  display: block;
}
</style>